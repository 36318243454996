import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"

import "../style/index.scss"

const NotFoundPage = () => (
  <Container fluid className="no-gutters">
  <Row className="fh align-items-center">
    <Col xs={"auto"} className="mx-auto">
      <div className="logo">
        {"404"}
      </div>
    </Col>
  </Row>
</Container>
)

export default NotFoundPage
